import React from 'react'
import Footer from './Footer'

const Myomactomy = () => {
    return (
        <>
            <div className='heading'>
                <h1>Myomectomy (for Fibroid Uterus) </h1>
                <p>Home / Myomectomy (for Fibroid Uterus)</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p><strong>Myomectomy for Fibroid Uterus:</strong></p>
                    <p>Myomectomy is a surgical procedure performed to remove fibroids from the uterus while preserving the uterus itself. Here's an overview:</p>
                    <p><strong>Indications:</strong></p>
                    <ul>
                        <li><strong>Fibroids:</strong> Myomectomy is indicated for women who have symptomatic fibroids causing symptoms such as heavy menstrual bleeding, pelvic pain, pressure, or infertility.</li>
                        <li><strong>Desire for Fertility Preservation:</strong> It is often recommended for women who wish to preserve their fertility or retain their uterus for personal or cultural reasons.</li>
                    </ul>
                    <p><strong>Procedure:</strong></p>
                    <p>During myomectomy:</p>
                    <ol>
                        <li><strong>Preparation:</strong> The patient is prepared for surgery, which may involve fasting and preoperative tests to assess overall health.</li>
                        <li><strong>Incision:</strong> The surgeon makes an incision in the abdomen (abdominal myomectomy) or accesses the uterus through the vagina and cervix (hysteroscopic myomectomy).</li>
                        <li><strong>Fibroid Removal:</strong> The surgeon carefully removes the fibroids from the uterus while preserving the uterine tissue. Techniques such as cutting, shaving, or enucleation may be used depending on the size and location of the fibroids.</li>
                        <li><strong>Uterine Repair:</strong> After the fibroids are removed, the surgeon may perform repair of the uterine tissue to ensure optimal healing and reduce the risk of complications such as excessive bleeding or adhesions.</li>
                        <li><strong>Closure:</strong> Once the procedure is completed, the incisions are closed, and the patient is monitored during the postoperative period.</li>
                    </ol>
                    <p><strong>Recovery:</strong></p>
                    <p>Recovery from myomectomy varies depending on factors such as the extent of the procedure, the size and number of fibroids removed, and the patient's overall health. Typically, patients are monitored closely postoperatively and may experience some discomfort or pain, which can be managed with medications.</p>
                    <p><strong>Follow-Up:</strong></p>
                    <p>After myomectomy, patients may require follow-up appointments to monitor their recovery, assess symptoms, and discuss any further treatment or surveillance.</p>
                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Myomactomy