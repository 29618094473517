import React from 'react'
import Footer from '../component/Footer'

const Hystroscopic = () => {
    return (
        <>
            <div className='heading'>
                <h1>Hysteroscopic Foregin Body Removal</h1>
                <p>Home / Hysteroscopic Foregin Body Removal</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p>In some cases of infertility or abnormal uterine bleeding, hysteroscopic foreign body removal may be necessary to address issues related to intrauterine devices (IUDs), retained products of conception (RPOC), or other foreign objects:</p>

                    <p><strong>Hysteroscopic Foreign Body Removal:</strong> This procedure involves using a hysteroscope, a thin, lighted instrument, to visualize and remove foreign bodies from the uterine cavity. Common foreign bodies that may require removal include displaced IUDs, retained placental tissue, or other objects inadvertently left in the uterus following a surgical procedure. Hysteroscopic foreign body removal is a minimally invasive technique that allows for precise visualization and extraction of foreign objects without the need for an abdominal incision.</p>

                    <p>The procedure is typically performed under general anesthesia or sedation in an outpatient setting. After the foreign body is successfully removed, patients may experience relief from symptoms such as pelvic pain, abnormal bleeding, or infertility related to the presence of the foreign object.</p>

                    <p>Hysteroscopic foreign body removal is considered a safe and effective intervention with low complication rates. It offers the advantage of avoiding more invasive surgical approaches and allowing for rapid recovery and return to normal activities.</p>

                    <p>If you suspect that you may have a foreign body in your uterus or if you are experiencing symptoms such as pelvic pain, abnormal bleeding, or infertility, it is important to consult with a healthcare provider for evaluation and appropriate management.</p>

                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Hystroscopic