import React from 'react'
import dr from '../img/dr.jpg';
import dr2 from '../img/gal3.jpg';
import Footer from './Footer';
import review from '../img/review.jpg';
import gogle from '../img/google.png';

const Clinic = () => {
    return (
        <>


            <div className='heading'>
                <h1>ABOUT US </h1>
                <p>Home / About Us </p>
            </div>


            <div className='about'>

                <div className='about1'>
                    <img src={dr2} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist,  & Infertility</h2>
                    <h2>M.B.B.S , D.G.O. (A.M.U)
                        D.N.B. (Apollo Hospital).</h2>

                    <br />
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}>
                        <b style={{ fontWeight: 'bold' }}>14 + Years</b> Of Experience
                    </p>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>
                    <p>Ex. Consultant at Apollo Medics Hospital, Lucknow.</p>
                    <p>On Advisory Board at Manav Kalyan Charitable Hospital, Haryana.</p>

                    <a href=''><button>CALL NOW </button></a>

                </div>

            </div>

            <br />
            <hr />




            <div className='about'>

                <div className='about1' style={{ backgroundColor: '#5e4e6d' }}>
                    <img src={dr} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist,  & Infertility</h2>
                    <h2>M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).
                    </h2>

                    <br />
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}>
                        <b style={{ fontWeight: 'bold' }}>14 + Years</b> Of Experience
                    </p>
                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>
                    <p>Ex. Assistant Professor at Hind Medical College, Barabanki.</p>
                    <p>Ex. Consultant at Apollo Medics Hospital, Lucknow.</p>


                    <a href=''><button>CALL NOW </button></a>

                </div>

            </div>
            <br /><br/>
            <center><h1>Google Reviews</h1></center>

            <div className='riview'>

                <div className='riview2' style={{ textAlign: 'center' }}>
                    <h1>Mom's Express Birth & Beyond</h1> <br />
                    <img src={review} />
                </div>

                <div className='riview2'>
                    <h1>Shricha pandey<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>During my pregnancy I shifted from Gurgaon to lucknow and was worried about changing the doctor, but I got the best doctors in town Dr. Mansi helped me throughout my pregnancy journey with all my stupid questions, also the delivery was done by Dr Mansi and Dr Garima, </p>
                </div>

                <div className='riview2'>
                    <h1>Preeti mishra Mishra<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>My experience is very good for Garima mam..because she is very cooperative nature& helpful nature... she listen everything very carefully.& give good suggestion for every patients..Thanku so much mam for my good delivery..me & my baby both r good for your treatment </p>
                </div>

                <div className='riview2'>
                    <h1>Akriti Jain<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>Awesome experience.. doctors are very cooperative. They listen to everything patiently and maintain a family type relationship. It's very easy to contact the doctor as she is always available on phone. I had my consultation with doctor Garima Gupta. Highly recommended</p>
                </div>
            </div>



            <div className='riview'>

                <div className='riview2' >
                    <h1>Arti yadav<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>Mom's express is of great help and support for pregnant couples. Staff is humble and fulfill all your demands. Doctors are genuine guide in the journey of pregnancy and are patient enough to clear all your doubts. Rooms are spacious. Facilities they provide is up to the mark. Recommended from my side.</p>
                </div>

                <div className='riview2'>
                    <h1>Minal Gulati<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>I am grateful and so happy to have chosen Dr Mansi shukul for my pregnancy journey...she guided me so well throughout my journey.. she was there for me at every point..  and provided me such wonderful care post operative also... i cant thank enough Dr Mansi and Dr Garima for the whole post operative care..</p>
                </div>

                <div className='riview2'>
                    <h1>Rakesh Mohapatra<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>My wife and I had an exceptional experience at Mom's Express - Indus Maternity Centre, where she received care from Dr. Garima Gupta and Dr. Mansi Shukul during her pregnancy.
                        Dr. Garima's expertise ...</p>
                </div>

                <div className='riview2'>
                    <h1>Richa singh<img src={gogle} width="55px" height="30px" /></h1>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <p>Very Good Doctor, Super excellent and Very Helping Nature in Every time during My Before Pregnancy,During Pregnancy and Also After Pregnancy So, I want Say She is Best Doctor in Lucknow .</p>
                </div>
            </div>


            <br />
            <Footer />
        </>
    )
}

export default Clinic