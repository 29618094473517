import React from 'react'
import logo from '../img/icon.jpg';

const Present = () => {
    return (
        <div className='present'>

            <div className='present2' style={{ display: 'flex' }}>

                <div className='logs'>
                <img src={logo} width="70" height="55" />

                </div>
                <div className='logs'>
                    <h1 style={{ color: '#5e4e6d' }}>MOM'S EXPRESS </h1>
                    <h1 className='disc'>BIRTH & BEYOND!</h1>
                </div>
            </div>
            <div className='present2  mansi'>

                <h1>Dr. Mansi Shukul.</h1>
                <p> 1/1, Vineet Khand , Opposite Jaipuria School, Gomtinagar, Lucknow, Uttar Pradesh -226010</p>
                {/* <p><b>Mon,Wed, Fri,:  </b>Morning</p>
                <p><b>Tues,Thurs, Sat,:  </b>Evening</p> */}

            </div>

           <div className='icons'>
           <i class="fa fa-map-marker"></i>
           </div>

            <div className='present2 garima'>

                <h1> Dr. Garima Gupta.</h1>
                <p> 1/1, Vineet Khand , Opposite Jaipuria School, Gomtinagar, Lucknow, Uttar Pradesh -226010</p>

                {/* <p><b>Tues,Thurs, Sat,:  </b>Morning</p>
                <p><b>Mon,Wed, Fri,:  </b>Evening</p> */}

            </div>
            <div className='icons'>
            <i class="fa fa-phone"></i>
           </div>

            <div className='present2 calls'>
                <h1>Call Us </h1>
                <p>+91 9140458191</p>
                <p>+91 8838291513</p>

            </div>
        </div>
    )
}

export default Present