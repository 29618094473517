import React from 'react'
import Footer from '../component/Footer'

const Preyconsept = () => {
    return (
        <>
            <div className='heading'>
                <h1>Pre-Conception Counselling </h1>
                <p>Home / Pre-Conception Counselling</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <h1>What is Pre-Conception Counselling?</h1>

                    <p>Preconception counseling is an appointment with your healthcare provider that helps you plan for a future pregnancy. You'll discuss your family history, risk factors, medical conditions and lifestyle. This appointment is an important part of a planned and healthy pregnancy.</p>


                    <p>Preconception counseling is defined as health education and promotion, allowing for risk assessment, intervention, and medical optimization before pregnancy to reduce the chances of poor obstetric, maternal, and fetal outcomes.</p>

                    <h1>What is the pre planning of Counselling?</h1>
                    <p>Preconceptual counselling is a session or meeting with a doctor by an expecting couple before conception. Such a meeting includes an assessment of potential risk factors and medical complications that may arise during the period of pregnancy.</p>




                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Preyconsept