import React from 'react'
import Footer from './Footer'
const Nicuicu = () => {
    return (
        <>
            <div className='heading'>
                <h1>ICU Care / N.I.C.U Care</h1>
                <p>Home / ICU Care / N.I.C.U Care</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <h1>What is care in the NICU?</h1>
                    <p>When babies are born early, have health problems, or a difficult birth they go to the hospital's NICU. NICU stands for "neonatal intensive care unit." There, babies get around-the-clock care from a team of experts. Most of these babies go to the NICU (NIK-yoo) within 24 hours of birth.</p>


                    <h1>What is care in the ICU?</h1>
                    <p>Intensive care units (ICUs) are specialist hospital wards that provide treatment and monitoring for people who are very ill. They're staffed with specially trained healthcare professionals and contain sophisticated monitoring equipment.</p>
                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Nicuicu