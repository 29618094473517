import React from 'react'
import Footer from './Footer'

const Complete = () => {
    return (
        <>
            <div className='heading'>
                <h1>Complete Workup for Infertile Couples</h1>
                <p>Home / Complete Workup for Infertile Couples</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p>A complete workup for infertile couples typically involves a series of medical assessments and tests aimed at identifying the underlying causes of infertility. Here's a comprehensive list of steps commonly included in such a workup:</p>

                    <p>1. <strong>Medical History and Physical Examination:</strong> A detailed medical history is obtained from both partners, including past medical conditions, surgeries, medications, and lifestyle factors. A physical examination may also be conducted to identify any physical abnormalities that could contribute to infertility.</p>

                    <p>2. <strong>Ovulation Assessment (for the female partner):</strong> This involves tracking menstrual cycles and assessing ovulation through methods such as basal body temperature charting, ovulation predictor kits, or ultrasound monitoring.</p>

                    <p>3. <strong>Hormonal Testing (for both partners):</strong> Blood tests to measure hormone levels, including follicle-stimulating hormone (FSH), luteinizing hormone (LH), estradiol, progesterone, testosterone, thyroid hormones, and prolactin. These tests help evaluate ovarian function, sperm production, and other hormonal imbalances.</p>

                    <p>4. <strong>Semen Analysis (for the male partner):</strong> Analysis of a semen sample to assess sperm count, motility, morphology, and other factors affecting sperm quality.</p>



                    <p>Following the completion of these tests, a fertility specialist can review the results and develop a personalized treatment plan tailored to address the specific causes of infertility identified in each couple. Treatment options may include lifestyle modifications, medication, assisted reproductive technologies (such as intrauterine insemination (IUI) or in vitro fertilization (IVF)), or surgical interventions.</p>

                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Complete