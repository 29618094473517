import React from 'react'
import '../component/Head.css';


const Head = () => {
    return (
        <>

            <div className='head'>

                <div className='head2'>
                    <div className='social'>
                        <div class="socia2">
                            <a href="" ><i className="fa fa-facebook-f" style={{backgroundColor:'#5281cc'}} ></i></a>
                            <a href=""><i className="fa fa-instagram" style={{backgroundColor:'#0dbeea'}}></i></a>
                            <a href=""><i className="fa fa-linkedin" style={{backgroundColor:' #84e6ee'}}></i></a>
                            <a href=""><i className="fa fa-youtube-play" style={{backgroundColor:'#ff3d30 '}}></i></a>
                            <a href=""><i className="fa fa-pinterest" style={{backgroundColor:'#f60e20'}}></i></a>
                            <a href=""><i className="fa fa-twitter" style={{backgroundColor:' #10b833'}}></i></a>
                        </div>    
                    </div>
                </div>


                <div className='head3'>

                    <div className='marks'>
                        <marquee>
                            <p>Adolescent Clinic ,  Gynecological Cancer Screening, Menopause Clinic, Vacuum Assisted Delivery</p>

                        </marquee>

                    </div>

                </div>


            </div>




        </>
    )
}

export default Head