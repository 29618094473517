import React from 'react'
import Footer from './Footer';

const Postnatel = () => {
    return (
        <>
            <div className='heading'>
                <h1>Post Natal Care of Mother</h1>
                <p>Home / Post Natal Care of Mother</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p>Women in the postnatal period need to maintain a balanced diet, just as they did during pregnancy. Iron and folic acid supplementation should also continue for 3 months after birth. Women who are breastfeeding require additional food and should drink sufficient clean water.</p>
                    <p>The care includes prevention, elimination, early detection and cure of health complications (if any), counselling on breastfeeding, immunization, an interactive session on the importance of birth spacing and maternal nutrition, and so on.</p>

                    <p>The major purposes of postpartum and postnatal care are to maintain and promote the health of the woman and her baby and to foster an environment that offers help and support to the extended family and community for a wide range of related health and social needs.</p>
                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Postnatel