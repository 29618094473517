import React from 'react'
import dr from '../img/dr.jpg';
import dr2 from '../img/gal3.jpg';

const About = () => {
    return (
        <>
            <div className='about'>

                <div className='about1 newimg'>
                    <img src={dr2} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist,  & Infertility</h2>
                    <h2>M.B.B.S , D.G.O. (A.M.U)
                        D.N.B. (Apollo Hospital).</h2>
                    <br />
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}>
                        <b style={{ fontWeight: 'bold' }}>14 + Years</b> Of Experience
                    </p>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>
                    <p>Ex. Consultant at Apollo Medics Hospital, Lucknow.</p>
                    <p>On Advisory Board at Manav Kalyan Charitable Hospital, Haryana.</p>

                    <a href='Tel:9140458191'><button>CALL NOW </button></a>

                </div>

            </div>

            <br />
            <hr />




            <div className='about'>

                <div className='about1' style={{ backgroundColor: '#5e4e6d' }}>
                    <img src={dr} />
                </div>
                <div className='about2'>
                    <h3>Meet Our Consultant</h3>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist,  & Infertility</h2>
                    <h2>M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).
                    </h2>

                    <br />
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}>
                        <b style={{ fontWeight: 'bold' }}>14 + Years</b> Of Experience
                    </p>
                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>
                    <p>Ex. Assistant Professor at Hind Medical College, Barabanki.</p>
                    <p>Ex. Consultant at Apollo Medics Hospital, Lucknow.</p>


                    <a href='Tel:9140458191'><button style={{ backgroundColor: '#5e4e6d' }}>CALL NOW </button></a>

                </div>

            </div>



        </>
    )
}

export default About