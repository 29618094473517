import React from 'react'
import obs from '../img/obs.png';
import gyne from '../img/gengyneo.png';
import lap from '../img/lapro.png';
import inf from '../img/infer.png';
import plan from '../img/plan.png';
import casm from '../img/cosmet.png';
import well from '../img/well.png';
import onco from  '../img/onco.png';
import uro from  '../img/uro.png';
import sono from '../img/sono.png';



const Treatment = () => {
    return (

        <div className='treatment'>

            <div className='treat'>
                <h1>Treatment & Specialties</h1>
                <p>We offer the entire range of services for women of all ages aligned to the field of gynecology and obstetrics under one roof. Not only you get all the answers of your questions comfortably but also we have the skill and experience to help you with best possible treatment.</p>
            </div>

            <div className='obstic'>
                <div className='obstic2'>

                    <div className='facs'>
                        <h1>Obstetrics</h1>
                        <img src={obs} />
                        <p>We provide state-of-the-art pregnancy & childbirth care for the vast majority of the women</p>
                    </div>

                    <div className='facs'>
                        <h1>General Gynecology</h1>
                        <img src={gyne} />
                        <p>Get accurate diagnosis, with detailed prognosis of numerous general gynecology problems</p>
                    </div>
                    <div className='facs'>
                        <h1>Laparoscopy</h1>
                        <img src={lap} />
                        <p>We perform various modern Laparoscopy surgeries that are safe and most effective in today's time</p>
                    </div>
                    <div className='facs'>
                        <h1>Infertility</h1>
                        <img src={inf} />
                        <p>Best in class with proven healthy pregnancies , we are helping patients to complete their families</p>
                    </div>
                    <div className='facs'>
                        <h1>Family Planning</h1>
                        <img src={plan} />
                        <p>We assist with proper education to make crucial life decisions for the infants, children and families</p>
                    </div>


                </div>


                <div className='obstic2'>
                    <div className='facs'>
                        <h1>Cosmetic Gynecology</h1>
                        <img src={casm} />
                        <p>Improve the appearance and make the body appear younger or more aesthetically-pleasing.</p>
                    </div>

                    <div className='facs'>
                        <h1>Urogynecology</h1>
                        <img src={uro} />
                        <p>Our specialists are trained to diagnose and treat a wide variety of pelvic floor related problems</p>
                    </div>
                    <div className='facs'>
                        <h1>Sonography</h1>
                        <img src={sono} />
                        <p>We can diagnose and manage all women health problems with state-of-the-art technologies</p>
                    </div>
                    <div className='facs'>
                        <h1>Oncology</h1>
                        <img src={onco} />
                        <p>We treat cancer in uterus, cervix, ovaries, and vagina via medical, surgical, and radiation</p>
                    </div>
                    <div className='facs'>
                        <h1>Well-Women Checkup</h1>
                        <img src={well} />
                        <p>Stay healthy by scheduling a well-women checkup visit to avoid any health risks</p>
                    </div>

                </div>
            </div>


        </div>









    )
}

export default Treatment