import React from 'react'
import Footer from './Footer'


const Gyneo = () => {
    return (
        <>
            <div className='heading'>
                <h1>Gynecological Cancer Screening</h1>
                <p>Home / Gynecological Cancer Screening</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>

                    <p>The Pap test (or Pap smear) and HPV test can help prevent cervical cancer or find it early. If you have a family history of breast, ovarian, uterine, or colorectal cancer, you may have a higher risk for developing these cancers. Talk to your doctor about ways to reduce your risk.</p>
                    <h1>What is the screening test for gynecological cancer?
                    </h1>
                    <p>The Pap test (or Pap smear) looks for precancers, cell changes on the cervix that might become cervical cancer if they are not treated appropriately.</p>
                    <h1>How do gynecologists check for cancer?</h1>
                    <p>A pap smear can detect cervical and uterine cancers. It is recommended to begin testing every three years starting at age 21. A pelvic exam examines the vagina, cervix, uterus, fallopian tubes, ovaries, and rectum.</p>


                    <h1>What are three types of cancer screening?</h1>

                    <p>Getting screening tests regularly may find breast, cervical, and colorectal (colon) cancers early, when treatment is likely to work best. Lung cancer screening is recommended for some people who are at high risk.</p>

                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Gyneo