import React from 'react'
import Footer from './Footer';
import gal1 from '../img/gal1.jpg'
import gal2 from '../img/gal2.jpg'
import gal3 from '../img/gal3.jpg'
import gal4 from '../img/gal4.jpg'
import gal5 from '../img/gal5.jpg'


import as1 from '../img/as (1).jpg'
import as2 from '../img/as (2).jpg'
import as3 from '../img/as (3).jpg'
import as4 from '../img/as (4).jpg'
import as5 from '../img/as (5).jpg'
import as6 from '../img/as (6).jpg'


const Gallery = () => {
    return (
        <>
            <div className='heading'>
                <h1>GALLERY </h1>
                <p>Home / Gallery </p>
            </div>



            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal1} />
                </div>
                <div className='gallery2'>
                    <img src={gal5} />
                </div>
                <div className='gallery2'>
                    <img src={gal3} />
                </div>
            </div>

            <div className='gallery'>
                <div className='gallery2'>
                    <img src={gal2} />
                </div>
                <div className='gallery2'>
                    <img src={gal4} />
                </div>
                <div className='gallery2'>
                   
                </div>
            </div>


            <div className='gallery'>
                <div className='gallery2'>
                    <img src={as1} />
                </div>
                <div className='gallery2'>
                    <img src={as2} />
                </div>
                <div className='gallery2'>
                <img src={as3} />
                </div>
            </div>


            <div className='gallery'>
                <div className='gallery2'>
                    <img src={as4} />
                </div>
                <div className='gallery2'>
                    <img src={as5} />
                </div>
                <div className='gallery2'>
                <img src={as6} />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Gallery

