import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

import Head from './component/Head.js';
import Present from './component/Present.js';
import Navbar from './component/Navbar';
import Home from './component/Home';
import Register from './component/Register';
import Dignostic from './component/Dignostic.js';

import Clinic from './Pages/Clinic.js';
import Contact from './Pages/Contact.js';
import Gallery from './Pages/Gallery';
import High from './Pages/High.js';
import Preyconsept from './Pages/Preyconsept.js';
import Delivery from './Pages/Delivery.js';
import Nicuicu from './Pages/Nicuicu.js';
import Postnatel from './Pages/Postnatel.js';
import Breast from './Pages/Breast.js';
import Family from './Pages/Family.js';
import Gyneo from './Pages/Gyneo.js';
import Menupause from './Pages/Menupause.js';
import Managharmon from './Pages//Managharmon.js';
import Abdominal from './Pages/Abdominal.js';
import Tuboplastic from './Pages/Tuboplastic.js';
import Turbosurgery from './Pages/Turbosurgery.js';
import Myomactomy from './Pages/Myomactomy.js';
import Complete from './Pages/Complete.js';
import Ivf from './Pages/Ivf.js';
import Ultrasound from './Pages/Ultrasound.js';
import Laprohectic from './Pages/Laprohectic.js';
import Laprovarin from  './Pages/Laprovarin.js';
import Lapromyomatic from './Pages/Lapromyomatic.js';
import LaproEndo from './Pages/LaproEndo.js';
import Hystroscopic from './Pages/Hystroscopic.js';






function App() {
  return (
    <div className='main'>
      <Router>
        <Head />
        <Present />
        <Navbar />
        <Routes>

          <Route path="*" element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Clinic" element={<Clinic />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/High" element={<High />} />
          <Route path="/Preyconsept" element={<Preyconsept />} />
          <Route path="/Delivery" element={<Delivery />} />
          <Route path="/Nicuicu" element={<Nicuicu />} />
          <Route path="/Postnatel" element={<Postnatel />} />
          <Route path="/Breast" element={<Breast />} />
          <Route path="/Family" element={<Family />} />
          <Route path="/Gyneo" element={<Gyneo />} />
          <Route path="/Menupause" element={<Menupause />} />
          <Route path="/Managharmon" element={<Managharmon />} />
          <Route path="/Abdominal" element={<Abdominal />} />
          <Route path="/Tuboplastic" element={<Tuboplastic />} />
          <Route path="/Turbosurgery" element={<Turbosurgery />} />
          <Route path="/Myomactomy" element={<Myomactomy />} />
          <Route path="/Complete" element={<Complete />} />
          <Route path="/Ivf" element={<Ivf />} />
          <Route path="/Ultrasound" element={<Ultrasound />} />
          <Route path="/Laprohectic" element={<Laprohectic />} />
          <Route path="/Laprovarin" element={<Laprovarin />} />
          <Route path="/Lapromyomatic" element={<Lapromyomatic />} />
          <Route path="/LaproEndo" element={<LaproEndo />} />
          <Route path="/Dignostic" element={<Dignostic />} />
          <Route path="/Hystroscopic" element={<Hystroscopic />} />






        </Routes>
      </Router>
    </div>
  );
}

export default App;
