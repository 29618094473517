import React from 'react';


const Frequent = () => {
  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2><br/>
      <div className="faq-item">
        <h3>1. Does Moms Express (Birth Beyond) provide 24x7 emergency services?</h3>
        <p>Moms Express (Birth Beyond) is known for providing timely intensive care to patients and you can get in touch with them to check what are the various emergency services offered by the hospital.</p>
      </div>
      <div className="faq-item">
        <h3>2. How can I book an appointment at Moms Express (Birth Beyond)?</h3>
        <p>You may be able to book an appointment online or via call. However, you can connect with the hospital helpline to fetch further details regarding their booking policy.</p>
      </div>
      <div className="faq-item">
        <h3>3. Is Moms Express (Birth Beyond) a speciality hospital?</h3>
        <p>Moms Express (Birth Beyond) is accredited for providing specialty treatments in Prenatal Check Up, etc.</p>
      </div>
      <div className="faq-item">
        <h3>4. What are the various healthcare amenities offered by Moms Express (Birth Beyond)?</h3>
        <p>Moms Express (Birth Beyond) in Gomti Nagar, Lucknow offers a variety of amenities including Wheel Chair Accessible Entrance And Exit, Wheel Chair Accessible In Car Parking, etc.</p>
      </div>
    </div>
  )
}

export default Frequent;
