import React from 'react'
import Footer from './Footer'

const Ultrasound = () => {
    return (
        <>
            <div className='heading'>
                <h1>Ultrasound Facility</h1>
                <p>Home / Ultrasound Facility</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p>As part of the infertility workup and treatment process, access to ultrasound facilities is crucial for monitoring ovarian function, follicular development, and uterine health. Ultrasound imaging plays a vital role in several aspects of infertility evaluation and treatment:</p>

                    <p><strong>Ovarian Assessment:</strong> Transvaginal ultrasound is commonly used to assess ovarian reserve and monitor follicular development during fertility treatments. This involves visualizing the ovaries to determine the number and size of follicles, which helps predict ovulation and optimize timing for procedures such as intrauterine insemination (IUI) or in vitro fertilization (IVF).</p>

                    <p><strong>Uterine Evaluation:</strong> Ultrasound imaging allows for the assessment of uterine anatomy, including the size, shape, and presence of any abnormalities such as fibroids, polyps, or uterine septum. This information is essential for determining the suitability of the uterus for embryo implantation and guiding treatment decisions.</p>

                    <p><strong>Monitoring Pregnancy:</strong> Following successful conception through fertility treatments, ultrasound scans are used to monitor early pregnancy development, confirm fetal viability, and assess for any complications such as ectopic pregnancy or miscarriage. Serial ultrasounds throughout pregnancy provide valuable information on fetal growth and well-being.</p>

                    <p>Most fertility clinics have onsite ultrasound facilities staffed by trained sonographers and reproductive endocrinologists. These facilities are equipped with state-of-the-art ultrasound machines capable of providing high-resolution imaging for accurate diagnosis and monitoring. Additionally, some clinics offer 3D/4D ultrasound capabilities, allowing for more detailed visualization of reproductive anatomy and fetal structures.</p>

                    <p>Access to ultrasound facilities within fertility clinics streamlines the evaluation and treatment process, ensuring timely and comprehensive care for couples undergoing infertility treatment.</p>

                </div>

                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Ultrasound