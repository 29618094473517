import React from 'react'
import Footer from './Footer'

const Delivery = () => {
    return (
        <>
            <div className='heading'>
                <h1>All Types of Delivery</h1>
                <p>Home / All Types of Delivery</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <h1>Normal Vaginal Delivery.</h1>
                    <p>A vaginal delivery is when a person gives birth through their vagina. Vaginal deliveries are the most common and most preferred method of delivery. This is because they are typically low-risk and carry the most benefits to the birthing person and baby.</p>

                    <h1>	Vacuum Assisted Delivery.</h1>
                    <p>During vacuum assisted vaginal delivery, the doctor or midwife will use a vacuum (also called a vacuum extractor) to help move the baby through the birth canal. The vacuum extractor uses a soft plastic cup that attaches to the baby's head with suction.</p>

                    <h1>Painless Vaginal Delivery</h1>
                    <p>Painless delivery can be achieved using a form of regional anaesthesia that provides pain relief during natural labour. Epidural anaesthesia is administered through an injection on the lower back of the mother. </p>

                    <h1>Painless Caesarean Section</h1>
                    <p>You won't feel any pain during the C-section, although you may feel sensations like pulling and pressure. Most women are awake and simply numbed from the waist down using regional anesthesia (an epidural and/or a spinal block) during a C-section. That way, they are awake to see and hear their baby being born.</p>

                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Delivery