import React from 'react'
import Carousal from './Carousal'
import About from './About'
import Treatment from './Treatment'
import Content from './Content'
import Footer from './Footer'
import Frenquent from './Frenquent'
import Sections from './Sections'

const Home = () => {
  return (

    <div className='main'>

      <Carousal />
      <Sections/>
      <hr/>
      <About />
      <br/>
      <Treatment />
      <Content />
      <Frenquent />
      <Footer />

      
    </div>


  )
}

export default Home