import React, { useState } from 'react';

const Content = () => {
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [time, setTime] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");

    const handleDoctorChange = (event) => {
        setSelectedDoctor(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        
        let message = `Booking Details:\n`;
        message += `Doctor: ${selectedDoctor}\n`;
        message += `Name: ${name}\n`;
        message += `Address: ${address}\n`;
        message += `Contact Number: ${contact}\n`;
        message += `Preferred Time: ${time}\n`;

       
        const whatsappNumber = "9140458191"; 
        const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

       
        window.open(whatsappURL, '_blank');

        setSelectedDoctor(null);
        setName("");
        setEmail("");
        setTime("");
        setAddress("");
        setContact("");
    };
    return (
        <div className='content'>
            <div className='content1'>
                <h2> Book Appointment </h2>
                <div>
                    <input
                        type="radio"
                        id="doctor1"
                        name="doctor"
                        value="Dr. Mansi Shukul"
                        checked={selectedDoctor === "Dr. Mansi Shukul"}
                        onChange={handleDoctorChange}
                    />
                    <label htmlFor="doctor1"> Dr. Mansi Shukul</label>
                </div>
                <div>
                    <input
                        type="radio"
                        id="doctor2"
                        name="doctor"
                        value="Dr. Garima Gupta"
                        checked={selectedDoctor === "Dr. Garima Gupta"}
                        onChange={handleDoctorChange}
                    />
                    <label htmlFor="doctor2"> Dr. Garima Gupta</label>
                </div>

                {selectedDoctor === "Dr. Mansi Shukul" && (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                        <label htmlFor="address">Address:</label>
                        <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        <label htmlFor="contact">Contact Number:</label>
                        <input type="tel" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)} required />
                        <label htmlFor="time">Preferred Time:</label>
                        <select id="time" name="time" value={time} onChange={(e) => setTime(e.target.value)} required>
                            <option value="">Select time</option>
                            <optgroup label="Morning">
                                <option value="Mon Morning">Monday</option>
                                <option value="Wed Morning">Wednesday</option>
                                <option value="Fri Morning">Friday</option>
                            </optgroup>
                            <optgroup label="Evening">
                                <option value="Tue Evening">Tuesday</option>
                                <option value="Thu Evening">Thursday</option>
                                <option value="Sat Evening">Saturday</option>
                            </optgroup>
                        </select>
                        <button type="submit">Submit</button>
                    </form>
                )}

                {selectedDoctor === "Dr. Garima Gupta" && (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                        <label htmlFor="address">Address:</label>
                        <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        <label htmlFor="contact">Contact Number:</label>
                        <input type="tel" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)} required />
                        <label htmlFor="time">Preferred Time:</label>
                        <select id="time" name="time" value={time} onChange={(e) => setTime(e.target.value)} required>
                            <option value="">Select time</option>
                            <optgroup label="Morning">
                                <option value="Tue Morning">Tuesday</option>
                                <option value="Thu Morning">Thursday</option>
                                <option value="Sat Morning">Saturday</option>
                            </optgroup>
                            <optgroup label="Evening">
                                <option value="Mon Evening">Monday</option>
                                <option value="Wed Evening">Wednesday</option>
                                <option value="Fri Evening">Friday</option>
                            </optgroup>
                        </select>
                        <button type="submit">Submit</button>
                    </form>
                )}
            </div>


            <div className='content2'>
                {/* <div className='melo'>
                    <h1>Mom's Express Birth & Beyond</h1>
                    <p>Mom's Express  Birth & Beyond in Gomti Nagar has a wide range of products and / or services
                        to cater to the varied requirements of their customers. The staff at this establishment are courteous
                        and prompt at providing any assistance. They readily answer any queries or questions that you may have. This establishment is functional from 10:00- 17:00 14:00-19:00.</p>
                </div> */}
            </div>

        </div>
    )
}

export default Content