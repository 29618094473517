import React from 'react'
import Footer from '../component/Footer'

const High = () => {
    return (
        <>
            <div className='heading'>
                <h1>High Risk Pregnancy </h1>
                <p>Home / High Risk Pregnancy</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <h1>What is High-Risk Pregnancy?</h1>

                    <p>Females can suffer from a high risk of pregnancy complications due to the following situations :</p>


                    <li>Weight of the females. Both, being underweight or overweight becomes an issue.</li>
                    <li>Age of the females. It is good to conceive at the right age, that is, females younger than 17 or older than 35 may have complications.</li>
                    <li>Pregnancy with triplets or twins.</li>
                    <li>Females who suffer from high blood pressure, thyroid, diabetes, and other health problems.</li>
                    <li>Serious issues like delivering a premature baby in the first pregnancy or have given birth to a child who has genetic disorders.</li>



                    <h1>How can a doctor help us in the health state of High-Risk Pregnancy?</h1>

                    <p>Our specialist Dr. Smiti will help females who suffer from High-Risk Pregnancy in the best possible way. She will closely monitor the patient in each term or month of pregnancy. Our doctor and the assisting staff will recommend the patient to eat a healthy and balanced diet.</p>
                    <p>The patient will be requested and guided to keep the infections and illnesses at bay. Alcohol, smoke, and meat will be immediately prohibited. And before anything else prevails, it will be the stress that needs to be reduced.</p>



                </div>


             
                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default High