import React from 'react'
import Footer from './Footer';

const Menupause = () => {
    return (
        <>
            <div className='heading'>
                <h1>Menopause </h1>
                <p>Home / Menopause</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>

                    <h1>Doctor, I think I am going through menopause. Can you please elaborate on the symptoms of menopause?</h1>
                    <p>Yes, please note the symptoms of the menopause problems are as under:</p>
                    <li>Vaginal Dryness.</li>
                    <li>Irregular Bleeding.</li>
                    <li>Depression and Anxiety.</li>
                    <li>Memory Issues.</li>
                    <li>Reduced Sex Drive.</li>
                    <li>Mood Swings.</li>
                    <li>Night Sweats.</li>
                    <li>Breast soreness.</li>


                    <h1>Doctor, can you tell me why menopause occurs in females?</h1>
                    <p>It is the natural process like others that happens when the ovaries age. A female when start with menopause will produce fewer hormones like estrogen, testosterone, progesterone, follicle-stimulating hormone, and luteinizing hormones. When ovaries get old, they do not release eggs leading to the absence of menstruation.</p>
                    <h1>What are other reasons that can cause menopause in women?</h1>
                    <p>Menopause can also happen when females take certain medications or undergo surgeries. Sometimes ovaries are removed surgically because they stop functioning. Pelvic injuries can also cause menopause that leads to the damage of ovaries.</p>



                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Menupause