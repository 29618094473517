import React from 'react'
import logo from '../img/icon.jpg';

const Sections = () => {
    return (
        <div className='sections'>
            <div className='sect2'>
                <img src={logo} />
            </div>

            <div className='sect3'>
                <h1>Team Mom Express Welcomes you on board.<br/>
                    Let us work to create an endearing journey of<br/> parenthood</h1>

                <h1>Get support & guidance throughout (24*7) <br/> from Experienced Women Specialists</h1>


            </div>
        </div>
    )
}

export default Sections