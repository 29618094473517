import React from 'react'
import Footer from './Footer'

const Ivf = () => {
    return (
        <>
            <div className='heading'>
                <h1>IUI/ IVF Services</h1>
                <p>Home / IUI/ IVF Services</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p>For couples undergoing infertility treatment, various assisted reproductive technologies (ART) are available to help achieve pregnancy. Two common procedures include:</p>

                    <p><strong>Intrauterine Insemination (IUI):</strong> Also known as artificial insemination, IUI involves placing sperm directly into the uterus around the time of ovulation. This procedure is often recommended for couples with unexplained infertility, mild male factor infertility, or cervical factor infertility. Prior to IUI, the female partner may undergo ovarian stimulation with fertility medications to increase the chances of successful ovulation.</p>

                    <p><strong>In Vitro Fertilization (IVF):</strong> IVF is a more complex fertility treatment where eggs are retrieved from the ovaries and fertilized with sperm in a laboratory dish. The resulting embryos are then transferred into the uterus. IVF may be recommended for couples with more severe infertility issues, such as tubal factor infertility, advanced maternal age, severe male factor infertility, or unexplained infertility. The IVF process typically involves ovarian stimulation, egg retrieval, fertilization, embryo culture, and embryo transfer.</p>

                    <p>Both IUI and IVF services are typically provided by fertility clinics or reproductive endocrinology specialists. These clinics offer comprehensive care, including initial consultations, diagnostic testing, treatment planning, and ongoing support throughout the fertility treatment process. The choice between IUI and IVF depends on factors such as the underlying cause of infertility, the couple's age, reproductive history, and preferences.</p>

                </div>

                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Ivf