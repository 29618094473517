import React from 'react'
import Footer from './Footer'
const Turbosurgery = () => {
    return (
        <>
            <div className='heading'>
                <h1>Surgery for Turbo-Ovaries Masses </h1>
                <p>Home / Surgery for Turbo-Ovaries Masses</p>
            </div>

            <div className='desiese'>

                <div className='desiese1'>
                    <p><strong>Surgery for Tubo-Ovarian Masses:</strong></p>
                    <p>When dealing with tubo-ovarian masses, surgical intervention may be necessary for diagnosis and treatment. Here's an overview:</p>
                    <p><strong>Indications:</strong></p>
                    <ul>
                        <li><strong>Diagnostic Purposes:</strong> Surgery may be performed to obtain tissue samples for biopsy to determine the nature of the mass—whether it's benign (non-cancerous) or malignant (cancerous).</li>
                        <li><strong>Treatment:</strong> Surgery may also be necessary to remove the mass, especially if it's causing symptoms or if there's suspicion of cancer.</li>
                    </ul>
                    <p><strong>Procedure:</strong></p>
                    <p>During surgery for tubo-ovarian masses:</p>
                    <ol>
                        <li><strong>Preparation:</strong> The patient is prepared for surgery, which may involve fasting and preoperative tests to assess overall health.</li>
                        <li><strong>Incision:</strong> The surgeon makes an incision in the abdomen, often using minimally invasive techniques such as laparoscopy.</li>
                        <li><strong>Exploration:</strong> The abdominal cavity is carefully explored to locate the tubo-ovarian mass and assess its characteristics.</li>
                        <li><strong>Biopsy:</strong> If necessary, tissue samples are collected from the mass for biopsy to determine whether it's benign or malignant.</li>
                        <li><strong>Mass Removal:</strong> If the mass is deemed problematic or suspicious, the surgeon may proceed to remove it. This may involve removing the affected ovary and fallopian tube (salpingo-oophorectomy) or excising the mass while preserving the surrounding structures.</li>
                        <li><strong>Closure:</strong> Once the procedure is completed, the incisions are closed, and the patient is monitored during the postoperative period.</li>
                    </ol>

                </div>



                <div className='desiese2'>
                    <h1>Dr. Mansi Shukul</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).
                    </h2>
                    <p>F.M.A.S (Fellowship in Minimal Access Surgery).</p>
                    <p>M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at Sunder Lal Jain Hospital, Delhi.</p>
                    <p>Ex. SR at Career Institute of Medical Science, Lucknow.</p>
                    <p>Ex. Laparoscopic Consultant at Lakshmi Madhavan Hospital, Tamil Nadu.</p>


                </div>

                <div className='desiese3'>
                    <h1>Dr. Garima Gupta</h1>
                    <h2>Obstetrician, Gynecologist, & Infertility
                        M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).</h2>

                    <p>D.N.B. (D.D.U Hospital, New Delhi)</p>
                    <p>F.I.C.O.G., M.R.C.O.G. (UK), M.N.A.M.S.</p>
                    <p>Ex. SR at D.D.U Hospital, New Delhi.</p>
                    <p>Ex. SR at Dr. RML Hospital & P.G.I.M.E.R., New Delhi.</p>
                    <p>Ex. Consultant at Srijan Fertility Institute, Patna.</p>

                </div>
            </div>

            <br />

            <Footer />
        </>
    )
}

export default Turbosurgery