import React, { useState } from 'react';
import Footer from '../component/Footer'

const Contact = () => {
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [time, setTime] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");

    const handleDoctorChange = (event) => {
        setSelectedDoctor(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();


        let message = `Booking Details:\n`;
        message += `Doctor: ${selectedDoctor}\n`;
        message += `Name: ${name}\n`;
        message += `Address: ${address}\n`;
        message += `Contact Number: ${contact}\n`;
        message += `Preferred Time: ${time}\n`;


        const whatsappNumber = "9140458191";
        const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;


        window.open(whatsappURL, '_blank');

        setSelectedDoctor(null);
        setName("");
        setEmail("");
        setTime("");
        setAddress("");
        setContact("");
    };
    return (
        <>

            <div className='heading'>
                <h1>CONTACT US </h1>
                <p>Home / Contact Us </p>
            </div>



            <div className='contat'>
                <div className='contat2'>
                    <div className='content1'>
                        <h2> Book Appointment </h2>
                        <div>
                            <input
                                type="radio"
                                id="doctor1"
                                name="doctor"
                                value="Dr. Mansi Shukul"
                                checked={selectedDoctor === "Dr. Mansi Shukul"}
                                onChange={handleDoctorChange}
                            />
                            <label htmlFor="doctor1"> Dr. Mansi Shukul</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="doctor2"
                                name="doctor"
                                value="Dr. Garima Gupta"
                                checked={selectedDoctor === "Dr. Garima Gupta"}
                                onChange={handleDoctorChange}
                            />
                            <label htmlFor="doctor2"> Dr. Garima Gupta</label>
                        </div>

                        {selectedDoctor === "Dr. Mansi Shukul" && (
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                <label htmlFor="address">Address:</label>
                                <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                                <label htmlFor="contact">Contact Number:</label>
                                <input type="tel" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)} required />
                                <label htmlFor="time">Preferred Time:</label>
                                <select id="time" name="time" value={time} onChange={(e) => setTime(e.target.value)} required>
                                    <option value="">Select time</option>
                                    <optgroup label="Morning">
                                        <option value="Mon Morning">Monday</option>
                                        <option value="Wed Morning">Wednesday</option>
                                        <option value="Fri Morning">Friday</option>
                                    </optgroup>
                                    <optgroup label="Evening">
                                        <option value="Tue Evening">Tuesday</option>
                                        <option value="Thu Evening">Thursday</option>
                                        <option value="Sat Evening">Saturday</option>
                                    </optgroup>
                                </select>
                                <button type="submit">Submit</button>
                            </form>
                        )}

                        {selectedDoctor === "Dr. Garima Gupta" && (
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                <label htmlFor="address">Address:</label>
                                <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                                <label htmlFor="contact">Contact Number:</label>
                                <input type="tel" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)} required />
                                <label htmlFor="time">Preferred Time:</label>
                                <select id="time" name="time" value={time} onChange={(e) => setTime(e.target.value)} required>
                                    <option value="">Select time</option>
                                    <optgroup label="Morning">
                                        <option value="Tue Morning">Tuesday</option>
                                        <option value="Thu Morning">Thursday</option>
                                        <option value="Sat Morning">Saturday</option>
                                    </optgroup>
                                    <optgroup label="Evening">
                                        <option value="Mon Evening">Monday</option>
                                        <option value="Wed Evening">Wednesday</option>
                                        <option value="Fri Evening">Friday</option>
                                    </optgroup>
                                </select>
                                <button type="submit">Submit</button>
                            </form>
                        )}
                    </div>
                </div>

                <div className='contat2'>
                    <h1>Get In Touch</h1>
                    <p>Our team of experts can help answer any questions you might have.
                        Please fill out the form below and a consultant will respond shortly.</p>

                    <p>ADDRESS – 1/1, Vineet Khand , Opposite Jaipuria School, Gomtinagar, Lucknow, Uttar Pradesh -226010</p>
                    <p>indusmaternity@gmail.com</p>
                    <p>+91 9140458191</p>
                    <p>+91 8838291513</p>


                </div>


            </div>

            <hr />
            <div className='maps'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d56946.128003922575!2d81.01178282065432!3d26.86751518110099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be3c88d3e0393%3A0x42124284dd5ad552!2sMom&#39;s%20Express%20(Indus%20Maternity%20Centre)!5e0!3m2!1sen!2sin!4v1712924760002!5m2!1sen!2sin" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>



            <Footer />
        </>
    )
}

export default Contact