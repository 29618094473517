import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
  return (

    <>
      <div className='footer'><br />
        <h1>If you Have Any Questions Call Us On +91 +91 9140458191</h1>
        <div className='footer1'>

          <div className='foot'>
            <h1>About us</h1>
            <p>Mom's Express Birth & Beyond in Gomti Nagar has a wide range of products and / or services to cater to the varied requirements of their customers. The staff at this establishment are courteous and prompt at providing any assistance.</p>
          </div>
          <div className='foot' style={{ textAlign: 'center' }}>
            <h1>Quick Links</h1><br />
            <Link to='/' className='link'>Home</Link><br />
            <Link to='/' className='link'>About</Link><br />
            <Link to='/' className='link'>Gallery</Link><br />
            <Link to='/' className='link'>Contact</Link>
          </div>
          <div className='foot'>
            <h1>Dr. Mansi Shukul</h1>
            <p>Obstetrician, Gynecologist, & Infertility
              M.B.B.S , D.G.O. (A.M.U) D.N.B. (Apollo Hospital).</p>
            <p><b>Mon,Wed, Fri,</b>: Morning</p>
            <p> <b>Tues,Thurs, Sat,</b>: Evening</p>
          </div>
          <div className='foot'>
            <h1>Dr. Garima Gupta</h1>
            <p>Obstetrician, Gynecologist, & Infertility
              M,B.B.S , D.G.O. ,(Lady Hardinge , New Delhi).
            </p>
            <p><b>Mon,Wed, Fri,</b>: Evening</p>
            <p> <b>Tues,Thurs, Sat,</b>:  Morning</p>
          </div>
          <div className='foot'>
            <h1>Contacts</h1>
            <p>+91 9140458191</p>
            <p>+91 8838291513</p>
            <p>ADDRESS – 1/1, Vineet Khand , Opposite Jaipuria School, Gomtinagar, Lucknow, Uttar Pradesh -226010</p>
            <p>Email: indusmaternity@gmail.com</p>
          </div>

        </div>

      </div>

      <div className='disclame'>
        <p>2024-25 ©  Mom's Express Birth & Beyond All Rights Reserved. Designed by Creative Digital World</p>
      </div>


    </>
  )
}

export default Footer